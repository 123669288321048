import axios from "axios";

const reportError = (error) => console.error(error);
const baseUrl = process.env.REACT_APP_API_BASE_URL;

export const apiFactory = (token) => {
  const businessApi = axios.create({
    baseURL: baseUrl,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const commercialApi = axios.create({
    baseURL: baseUrl,
    timeout: 2000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const naicsApi = axios.create({
    baseURL: baseUrl,
    timeout: 10000,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  return {
    commercial: {
      startApplication: (data) =>
        commercialApi
          .post("/customer/", data)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      searchNaics: (data) =>
        naicsApi
          .get(`/ams/naics?searchTerm=${data}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      getMLQuotes: (magicLink) =>
        commercialApi
          .get(`/quote/email/quotes/${magicLink}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      selectQuoteFromML: (appId, quoteId) =>
        commercialApi
          .post(
            `/quote/email/application/${appId}/quote/${quoteId}?selected=true`
          )
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
    },
    business: {
      createCyberQuote: (quote) =>
        businessApi
          .post("/quote/cyber", quote)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      updateApplication: (appId, quote) =>
        businessApi
          .put(`/quote/cyber/${appId}`, quote)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      submitApplication: (appId) =>
        businessApi
          .put(`/quote/cyber/${appId}/submit`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      resubmitApplication: (appId, quote) =>
        businessApi
          .post(`/quote/cyber/${appId}/resubmit`, quote)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      getApplication: (email) =>
        businessApi
          .get(`/quote/cyber/application?email=${email}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      getApplications: (email) =>
        businessApi
          .get(`/quote/application?email=${email}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      updateLob: (email, lob) =>
        businessApi
          .put(`/customer/${email}/lob`, lob)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      getUser: (email) =>
        businessApi
          .get(`/customer/${email}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      updateUser: (email, payload) =>
        businessApi
          .put(`/customer/${email}`, payload)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      getQuotes: (email) =>
        businessApi
          .get(`/quote/cyber/quotes?email=${email}`)
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
      selectQuote: (appId, quoteId) =>
        businessApi
          .post(
            `/quote/cyber/application/${appId}/quote/${quoteId}?selected=true`
          )
          .then((response) => response.data)
          .catch((error) => reportError(error.response.data)),
    },
  };
};
