import React from "react";
export default [
  {
    carrier: "Coalition",
    description: (
      <p>
        Coalition offers industry-leading coverage along with cutting edge risk
        management services. Their risk management services have resulted in a
        lower claim frequency relative to the industry, often leading to very
        competitive pricing. Coalition provides the information organizations
        need to increase cyber security, including personalized risk
        assessments. However, Coalition’s underwriting and risk management
        focuses on those organizations that have implemented cyber security
        protections, or will, in order to increase protections and reduce cyber
        incidents. Coalition has introduced Active Insurance, a new model
        designed to prevent digital risk before it strikes:
        <blockquote>
          Coalition brings together in-depth technology, cybersecurity, and
          insurance expertise to help organizations assess, prevent, and respond
          to an emerging set of digital risks. Its in-house security, claims,
          and incident response teams support brokers and policyholders before,
          during and after an incident occurs, taking a holistic approach to
          digital risk. Coalition’s Active Risk Platform analyzes complex sets
          of public data, threat intelligence, and proprietary claims
          information to create personalized risk assessments and threat
          monitoring that goes far beyond traditional insurance.
        </blockquote>
        A Coalition Risk Assessment is provided for all clients as part of the
        quote process, and all policyholders receive active monitoring.
        Personalized alerts and cybersecurity recommendations are periodically
        provided to policyholders and brokers.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Industry leading coverage and services, including 24/7 incident
          response, active monitoring and reporting resources
        </li>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Robust deceptive funds transfer (sublimit) - Fraudulent Funds Transfer
          (FFT) covered as a result of any electronic communication, including
          certain theft of money and securities, typically subject to a
          sublimit. Cost to reimburse customer's loss of funds covered if the
          impersonating communication or website was the result of insured's
          security failure.
        </li>
        <li>
          Failure to maintain security not excluded - Claims resulting from
          failure to maintain security standards are not excluded.
        </li>
        <li>Full prior acts – built into the policy form.</li>
        <li>
          State actor events typically covered - State actor attacks are not
          specifically excluded, but war, etc is excluded with a carveback for
          cyber terrorism (defined term)
        </li>
        <li>Business interruption at full limits</li>
        <li>
          Covers PCI fines & assessments - includes coverage for PCI fines &
          penalties, including fraud and card reissue charges.
        </li>
        <li>Includes dependent business interruption</li>
        <li>
          Data definition includes confidential corporate data - Definition of
          data includes personal information (PII), non-public data (like
          confidential corporate data) and non-electronic data.
        </li>
        <li>Worldwide Coverage - Territory is full worldwide.</li>
        <li>
          Intentional acts – Partial - Dishonest acts are excluded; intentional
          acts are not excluded except for fraudulent acts of senior executives.
        </li>
        <li>
          Media activities can be covered, along with intellectual property
          except for patent infringement.
        </li>
        <li>
          Third party bodily injury/property damage is not covered; only covered
          by endorsement.
        </li>
        <li>Hammer clause – 50/50 sharing of excess amount.</li>
        <li>
          Contractual liability excluded with carvebacks (security of data,
          misappropriation of ideas, PCI fines, if liability exists in absence
          of contract).
        </li>
      </ul>
    ),
  },
  {
    carrier: "CFC",
    description: (
      <p>
        CFC offers industry-leading coverage along with comprehensive risk
        management services, including an app. Their 24/7 incident response
        services, one of the first in the industry, is a valuable service for
        insureds if and when a cyber event occurs. Risk management services
        include a mobile app, employee training and awareness videos, risk
        rating reports, and dark web monitoring.
        <blockquote>
          Cyber 3.0 is a comprehensive first- and third-party cyber, privacy and
          media policy designed to cover the major risks associated with the use
          of data and technology within any modern organization. Designed to
          plug the gaps left by most traditional insurance policies, Cyber 3.0
          has evolved over a period of more than ten years to address the
          changing risk profile of companies that rely on technology to run
          their business.
        </blockquote>
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Leading 24/7 incident response - Incident response services are 24/7
          and high quality.
        </li>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Full prior acts – Full prior acts covered by default at no additional
          cost; concept of prior acts, and the retroactive date, removed from
          the policy.
        </li>
        <li>
          Robust deceptive funds transfer (sublimit) - Fraudulent Funds Transfer
          (FFT) covered, including certain theft of money and securities,
          typically subject to a sublimit, including cost to reimburse
          customer's loss of funds. Full computer crime coverage is standard,
          including theft of money. Cyber crime cover including cover for
          unauthorized transfer of funds, telephone hacking, phishing scams,
          social engineering, theft of personal funds and losses arising from
          cryptojacking and botnetting. Typically subject to a sublimit.
        </li>
        <li>
          Failure to maintain security not excluded - Claims resulting from
          failure to maintain security standards are not excluded.
        </li>
        <li>Business interruption at full limits</li>
        <li>
          Data definition includes confidential corporate data - Definition of
          data includes personal information (PII), non-public data (like
          confidential corporate data) and non-electronic data.
        </li>
        <li>
          Covers PCI fines & assessments - includes coverage for PCI fines &
          penalties, including fraud and card reissue charges.
        </li>
        <li>Worldwide coverage - Territory is full worldwide.</li>
        <li>
          Intentional acts – Partial: dishonest & intentional acts are excluded
          but restricted to acts of senior executives.
        </li>
        <li>
          Bodily injury/property damage covered, however 3rd party bodily
          injury/property damage is excluded - Contingent bodily injury may be
          covered on a separate healthcare cyber form designed for healthcare
          entities. Hardware replacement costs are covered (both direct &
          contingent).
        </li>
        <li>No exclusion for contractual liability </li>
        <li>
          State actor – coverage for state-actor events may be limited. Coverage
          for terrorism and state actors not fully clear. Acts of war are
          excluded; no mention of state actor attacks, but coverage may be
          subject via Lloyds state actor cyber attack policy, likely by
          endorsement. Terrorism is excluded with a carveback for cyber events.
        </li>
        <li>
          Dependent business Interruption only partially covered - Dependent
          business interruption coverage for tech supply chain partners and
          named non-tech supply chain partners.
        </li>
        <li>
          Media activities can be covered, along with intellectual property
          except for patent infringement. Comprehensive media cover including:
          on and offline media, IP infringement, defamation, negligent content,
          social media and user generated content
        </li>
        <li>Hammer clause - 80/20 sharing of excess amount.</li>
        <li>
          Comprehensive privacy liability including: full limits for regulatory
          actions, cover for contractual breaches (including NDAs,
          confidentiality indemnities, merchant agreement breaches, and breaches
          of companies own privacy policy), and cover for PCI related fines and
          penalties.
        </li>
        <li>
          Privacy breach notification including: legal costs to draft letters,
          cost to administer breach response, cost to cover forensic
          investigations, and credit monitoring
        </li>
        <li>
          Comprehensive cyber liability including: virus and hacking liability
          cover
        </li>
        <li>
          System damage and business interruption including: all risks data
          recovery and system restoration, hardware replacement costs, loss of
          revenue cover arising from virus, hacking, malicious insider or system
          failure. Now includes additional extra expense cover, providing cover
          for extra expenses without applying an economic test.
        </li>
        <li>
          Consequential reputational harm cover including cover for loss of
          profits occurring as a result of damage to the brand due to a cyber
          event
        </li>
      </ul>
    ),
  },
  {
    carrier: "Beazley",
    description: (
      <p>
        Beazley offers comprehensive coverage along with strong risk management
        services. Their 24/7 incident response division is a valuable service
        for insureds. Risk management services include access to a network of
        cyber security and incident response services.
        <blockquote>
          Beazley’s innovative Breach Response policy provides comprehensive
          breach response services through its industry leading BBR Services
          group that works with insureds and Beazley’s network of breach
          response service providers to thoroughly and efficiently respond to a
          breach incident. Breach response services include legal advice,
          computer security experts, and a PCI Forensic Investigator and
          Qualified Security Assessor for a PCI related breach. The breach
          response services also include resources to notify impacted
          individuals, access to a call center to respond to inquiries, credit
          and identity monitoring, and public relations and crisis management
          costs.
        </blockquote>
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Industry leading coverage and cyber security services, including 24/7
          incident response, active monitoring and reporting resources.
        </li>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating.
        </li>
        <li>
          Coverage determinations can be even more complicated than other Cyber
          Insurance policy forms due to the complexity of this policy wording.
        </li>
        <li>
          Robust deceptive funds transfer (sublimit) - Fraudulent Funds Transfer
          (FFT) covered as a result of any electronic communication, including
          certain theft of money and securities, typically subject to a
          sublimit. However, FFT of client funds are not covered in the base
          policy form.
        </li>
        <li>
          Failure to maintain security not excluded - Claims resulting from
          failure to maintain security standards are not excluded.
        </li>
        <li>Worldwide Coverage - Territory is full worldwide.</li>
        <li>
          Data definition includes confidential corporate data - Definition of
          data includes personal information (PII), non-public data (like
          confidential corporate data) and non-electronic data.
        </li>
        <li>Includes dependent business interruption</li>
        <li>Full prior acts – built into the policy form</li>
        <li>
          Covers PCI fines & assessments - includes coverage for PCI fines &
          penalties, including fraud and card reissue charges.
        </li>
        <li>
          Intentional acts – Partial exclusion. Dishonest acts are excluded;
          intentional acts are excluded as well with a carveback.
        </li>
        <li>
          State actor – Coverage for state-actor events may be limited. Coverage
          for terrorism and state actors not fully clear. Acts of war are
          excluded; no mention of state actor attacks, but coverage may be
          subject via Lloyds state actor cyber attack policy, likely by
          endorsement. Terrorism is excluded with a carveback for cyber events.
        </li>
        <li>3rd party bodily injury/property damage is excluded.</li>
        <li>Hammer clause - 60/40 sharing of excess amount.</li>
      </ul>
    ),
  },
  {
    carrier: "Corvus",
    description: (
      <p>
        Corvus offers comprehensive coverage along with strong risk management
        services. Their 24/7 incident response division is a valuable service
        for insureds. Risk management services include monitoring and risk
        assessments.
        <blockquote>
          Smart Cyber Insurance is a technology-enabled monoline cyber liability
          policy that utilizes best-in-class web scanning tools to assess an
          organization’s IT security hygiene throughout the policy period.
          Policyholders are provided with risk prioritized assessments that may
          red-flag cyber vulnerabilities arising from internal and external
          sources. Smart Cyber Insurance includes broad first party and third
          party insuring agreements in the base policy form. Additional coverage
          enhancements may be available via endorsement
        </blockquote>
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>Corvus provides an industry leading policy form.</li>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Failure to maintain security not excluded - Claims resulting from
          failure to maintain security standards are not excluded.
        </li>
        <li>
          Full prior acts – Full prior acts covered by default at no additional
          cost.
        </li>
        <li>
          State actor events typically covered - State actor attacks are not
          specifically excluded, but war, etc is excluded.
        </li>
        <li>Worldwide Coverage - Territory is full worldwide.</li>
        <li>
          Data definition includes confidential corporate data - Definition of
          data includes personal information (PII), non-public data (like
          confidential corporate data) and non-electronic data.
        </li>
        <li>
          Covers PCI fines & assessments - includes coverage for PCI fines &
          penalties, including fraud and card reissue charges.
        </li>
        <li>Hammer clause - 70/30 sharing of excess amount.</li>
        <li>3rd party bodily injury/property damage is excluded.</li>
      </ul>
    ),
  },
  {
    carrier: "Tokio Marine HCC",
    description: (
      <p>
        Tokio Marine HCC (“HCC”) offers comprehensive coverage through their
        NetGuard Plus product which includes a range of coverage parts.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Industry leading cyber security and mitigation services, including
          24/7 incident response, active monitoring and reporting resources
        </li>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Robust deceptive funds transfer (sublimit) - Fraudulent Funds Transfer
          (FFT) covered as a result of any electronic communication, including
          certain theft of money and securities, typically subject to a
          sublimit.{" "}
        </li>
        <li>
          Failure to maintain security not excluded - Claims resulting from
          failure to maintain security standards are not excluded.
        </li>
        <li>
          Data definition includes confidential corporate data - Definition of
          data includes personal information (PII), non-public data (like
          confidential corporate data) and non-electronic data.
        </li>
        <li>
          Full prior acts – Full prior acts covered by default at no additional
          cost; concept of prior acts, and the retroactive date, removed from
          the policy.
        </li>
        <li>Business interruption at full limits</li>
        <li>
          Covers PCI fines & assessments - includes coverage for PCI fines &
          penalties, including fraud and card reissue charges.
        </li>
        <li>
          Intentional acts – Partial - Dishonest acts are excluded; intentional
          acts are not excluded except for fraudulent acts of senior executives.
        </li>
        <li>Coverage is not provided for dependent business interruption.</li>
        <li>Coverage for state actor incidents is limited.</li>
        <li>Bodily injury/property damage related claims are excluded.</li>
        <li>Contractual liability is excluded with carvebacks.</li>
      </ul>
    ),
  },
  {
    carrier: "At Bay",
    description: (
      <p>
        At-Bay offers comprehensive coverage through their NetGuard Plus product
        which includes a range of coverage parts. In addition, the insurer may
        not have the same level of experience, size and financial security
        rating as other insurers – check the insurer.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Cyber security monitoring, including a cyber risk assessment report,
          included with coverage.
        </li>
        <li>
          Full prior acts – Full prior acts covered by default at no additional
          cost; concept of prior acts, and the retroactive date, removed from
          the policy.
        </li>
        <li>Coverage is included for dependent business interruption.</li>
        <li>Worldwide Coverage - Territory is full worldwide.</li>
        <li>3rd party bodily injury/property damage is excluded.</li>
      </ul>
    ),
  },
  {
    carrier: "Cowbell - Prime 100",
    description: (
      <p>
        Cowbell offers a limited coverage, competitively priced option called
        Prime 100. The tradeoff in reduced coverage comes with a competitive
        price, but certain types of cyber events will not be covered. The gaps
        in coverage make this product less desirable. In addition, the insurer
        may not have the same level of experience, size and financial security
        rating as other insurers – check the insurer.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Base policy form not as comprehensive – coverage gaps are material.
          While potentially a less expensive coverage option, you may be trading
          coverage for price. Coverage is pared back in the base ISO policy and
          in the endorsements and sub-limits offered. Make sure that the
          coverage is comprehensive enough for your organization.
        </li>
        <li>
          Look closely at the sublimits provided – these are often lower than
          competitors.
        </li>
        <li>
          Cyber Risk Report – Cowbell offers a risk assessment that may be
          useful to insureds.
        </li>
        <li>
          Designed for SMB – coverage is designed for those insureds that want a
          starter policy with reduced coverage for a competitive price, but this
          approach may leave critical gaps in coverage.
        </li>
        <li>Full Prior Acts - Typically Quoted</li>
        <li>
          Limited Deceptive Funds Transfer – this important coverage is not
          robust in this form. Social engineering coverage requires a completed
          documented verification procedure for coverage to be provided.
        </li>
        <li>
          Failure to maintain cyber security – This underwriter requires cyber
          security protections to be in place, and failure to do so could result
          in reduced coverage.
        </li>
        <li>
          Business interruption coverage is provided, but interruptions due to
          system failure or voluntary shutdown are not covered.
        </li>
      </ul>
    ),
  },
  {
    carrier: "Cowbell - Prime 250",
    description: (
      <p>
        The Prime 250 offering from Cowbell is a significant step up from their
        base product. The insurer may not have the same level of experience,
        size and financial security rating as other insurers – check the
        insurer.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Cyber security monitoring, including a cyber risk assessment report,
          included with coverage.
        </li>
        <li>
          Full prior acts may be included, but check for this coverage to be
          provided.
        </li>
      </ul>
    ),
  },
  {
    carrier: "Elpha Secure - Standard",
    description: (
      <p>
        ElphaSecure has a unique approach to Cyber Insurance and cyber security
        protections, and two products that reflect varying degrees of coverage
        and cyber security measures. ElphaSecure ties Cyber Insurance coverage
        directly to stringent cyber security requirements, and also prices
        accordingly. ElphaSecure may be a viable market for those organizations
        that present higher risk exposures but have or are committed to strong
        cyber security. ElphaSecure will also provide assistance for the insured
        to put strong cyber security measures in place.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Leading active monitoring & reporting – ElphaSecure takes a unique
          approach to risk management: It has developed its own cyber security
          software. The insured may, and in some cases is required, to install
          ElphaSecurity monitoring software. If the ElphaSecure software is
          installed, it must be installed on all (eligible) computers.
        </li>
        <li>Products – ElphaSecure offers two products:</li>
        <ul>
          <li>
            Standard – there is no need to install ElphaSecure, but the insured
            must meet four key criteria, either their own tools or via
            installing ElphaSecure software.
          </li>
          <li>
            Enhanced – the Enhanced product is only available if the insured
            downloads ElphaSecure software. Coverage includes additional
            features such as lower extortion retention, 1 year
            period-of-restoration for business interruption, and some other
            enhancements
          </li>
        </ul>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Failure to Maintain Security – this underwriter requires cyber
          security measures to be in place, and reduces or eliminates coverage
          if a claim results from a situation where required specific cyber
          security measures are not in use. Key cyber security measures include
          multifactor authentication (MFA), endpoint detection & response (EDR),
          patching/updates within 5 days, and offsite encrypted backups.
        </li>
        <ul>
          <li>
            What happens if cyber security is not in place and a claim occurs?
            Coverage will likely be prorated via a co-insurance provision with
            the determination based on the percentage of compliance with
            eligibility requirements.
          </li>
        </ul>
        <li>
          Designed for SMB – ElphaSecure has designed a combination of coverage
          and cyber security for SMBs, including those SMBs that do not have the
          internal expertise to implement cyber security.
        </li>
        <li>
          Robust Deceptive Funds Transfer (Sublimit) – funds transfer fraud
          (FFT) coverage is provided, including some types of claims at full
          limits. However, coverage requires cyber security practices in order
          to obtain full coverage, such as independent verification of funds
          transfers.
        </li>
        <li>Coverage requires suits to be brought within the US.</li>
        <li>Intentional acts are not excluded.</li>
      </ul>
    ),
  },
  {
    carrier: "Elpha Secure - Enhanced",
    description: (
      <p>
        ElphaSecure has a unique approach to Cyber Insurance and cyber security
        protections, and two products that reflect varying degrees of coverage
        and cyber security measures. ElphaSecure ties Cyber Insurance coverage
        directly to stringent cyber security requirements, and also prices
        accordingly. ElphaSecure may be a viable market for those organizations
        that present higher risk exposures but have or are committed to strong
        cyber security. ElphaSecure will also provide assistance for the insured
        to put strong cyber security measures in place.
      </p>
    ),

    coverageHighlights: (
      <ul>
        <li>
          Leading active monitoring & reporting – ElphaSecure takes a unique
          approach to risk management: It has developed its own cyber security
          software. The insured may, and in some cases is required, to install
          ElphaSecurity monitoring software. If the ElphaSecure software is
          installed, it must be installed on all (eligible) computers.
        </li>
        <li>Products – ElphaSecure offers two products:</li>
        <ul>
          <li>
            Standard – there is no need to install ElphaSecure, but the insured
            must meet four key criteria, either their own tools or via
            installing ElphaSecure software.
          </li>
          <li>
            Enhanced – the Enhanced product is only available if the insured
            downloads ElphaSecure software. Coverage includes additional
            features such as lower extortion retention, 1 year
            period-of-restoration for business interruption, and some other
            enhancements
          </li>
        </ul>
        <li>
          Leading insurer with long experience, significant size and strong
          financial strength rating
        </li>
        <li>
          Failure to maintain cyber security – this underwriter requires cyber
          security measures to be in place, and reduces or eliminates coverage
          if a claim results from a situation where required specific cyber
          security measures are not in use. Key cyber security measures include
          multifactor authentication (MFA), endpoint detection & response (EDR),
          patching/updates within 5 days, and offsite encrypted backups.
        </li>
        <ul>
          <li>
            What happens if cyber security is not in place and a claim occurs?
            Coverage will likely be prorated via a co-insurance provision with
            the determination based on the percentage of compliance with
            eligibility requirements.
          </li>
        </ul>
        <li>
          Designed for SMB – ElphaSecure has designed a combination of coverage
          and cyber security for SMBs, including those SMBs that do not have the
          internal expertise to implement cyber security.
        </li>
        <li>
          Robust Deceptive Funds Transfer (Sublimit) – funds transfer fraud
          (FFT) coverage is provided, including some types of claims at full
          limits. However, coverage requires cyber security practices in order
          to obtain full coverage, such as independent verification of funds
          transfers.
        </li>
        <li>Coverage requires suits to be brought within the US.</li>
        <li>Intentional acts are not excluded.</li>
      </ul>
    ),
  },
  {
    carrier: "Node",
    description: (
      <p>
        Node provides reduced coverage relative to leading Cyber insurers.
        However, Node may be a market for those higher risk accounts that may
        not have comprehensive cyber security in place. The underwriter is
        focused on cyber security and has stringent cyber security maintenance
        requirements. In addition, Node provides access to cyber security for
        insureds to enhance their cyber security profile.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          Failure to Maintain Security – This underwriter requires cyber
          security protections to be in place, and failure to do so could result
          in reduced coverage.
        </li>
        <li>
          Cyber Security Representations – The application process requires the
          insured represent that certain cyber security protections are in
          place. Carefully check the application questions & answers
        </li>
      </ul>
    ),
  },
  {
    carrier: "FusionMGA",
    description: (
      <p>
        FusionMGA provides reduced coverage relative to leading Cyber insurers.
        However, FusionMGA may be a market for those accounts with higher
        exposures. The underwriter is focused on cyber security, and has
        stringent cyber security maintenance requirements. In addition,
        FusionMGA provides access to cyber security for insureds to enhance
        their cyber security profile.
      </p>
    ),
    coverageHighlights: (
      <ul>
        <li>
          May be a market for higher exposure accounts, or those accounts
          without robust cyber security measures in place.
        </li>
        <li>
          Failure to maintain security – This underwriter requires cyber
          security protections to be in place, and failure to do so could result
          in reduced coverage.
        </li>
        <li>
          Cyber security representations – The application process requires the
          insured represent that certain cyber security protections are in
          place. Carefully check the application questions & answers
        </li>
        <li>
          The insurer may not have the same level of experience, size and
          financial security rating as other insurers – check the insurer.
        </li>
        <li>
          Designed for SMB – coverage is designed for those insureds that want a
          starter policy with reduced coverage for a competitive price, but this
          approach may leave critical gaps in coverage. 10
        </li>
      </ul>
    ),
  },
];
