import React from "react";
import { Route, Routes, Outlet, useLocation } from "react-router-dom";
import QuoteGrid from "../pages/quotes/QuoteGrid";
import Landing from "../pages/Landing";
import Binder from "../pages/Binder";
import Account from "../pages/Account";
import LoadingGif from "../components/LoadingGif";
import Application from "../pages/application/Application";
import GetStarted from "../pages/application/GetStarted";
import Success from "../pages/Success";
import { useAuth0 } from "@auth0/auth0-react";
import ExternalSelection from "../pages/ExternalSelection";

const PrivateRoute = () => {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated ? <Outlet /> : <DirectLogin />;
};

const DirectLogin = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const location = useLocation();
  if (!isAuthenticated && !isLoading) {
    loginWithRedirect({ appState: { targetUrl: location.pathname } });
  }

  return <LoadingGif />;
};

const AppRoutes = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  return (
    <>
      {isLoading ? (
        <LoadingGif />
      ) : (
        <Routes>
           <Route exact path="/landing" element={<Landing />} />
          {isAuthenticated && !isLoading && (
            <Route path="/" element={<Binder />} />
          )}
          <Route path="/account" element={<PrivateRoute />}>
            {isAuthenticated && <Route path="/account" element={<Account />} />}
          </Route>
          <Route path="/application" element={<PrivateRoute />}>
            {isAuthenticated && (
              <Route path="/application" element={<Application />} />
            )}
          </Route>
          <Route path="/application/:appId" element={<PrivateRoute />}>
            {isAuthenticated && (
              <Route path="/application/:appId" element={<Application />} />
            )}
          </Route>
          <Route path="/success" element={<PrivateRoute />}>
            {isAuthenticated && <Route path="/success" element={<Success />} />}
          </Route>
          <Route path="/coverage-selected" element={<PrivateRoute />}>
            {isAuthenticated && (
              <Route
                path="/coverage-selected"
                element={<ExternalSelection />}
              />
            )}
          </Route>
          <Route path="/quotes" element={<PrivateRoute />}>
            <Route path="/quotes" element={<QuoteGrid />} />
          </Route>

          {!isAuthenticated && !isLoading && (
            <Route exact path="/" element={<Landing />} />
          )}
           {!isAuthenticated && !isLoading && (
            <Route exact path="/youragency" element={<Landing />} />
          )}
           {isAuthenticated && !isLoading && (
            <Route exact path="/youragency" element={<Binder />} />
          )}
          <Route path="/getstarted" element={<GetStarted />} />
          <Route path="/myquotes" element={<QuoteGrid />} />
          <Route component={() => <DirectLogin />} />
        </Routes>
      )}
    </>
  );
};

export default AppRoutes;
