import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Header, Paragraph, Row, CtaButton } from "../styles/global";

const Landing = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: window.location.origin,
      },
    });
  };
  return (
    <>

      <Wrapper>
        <StyledHeader>Get quotes in under 15 minutes in just 5 easy steps</StyledHeader>
        <SubText></SubText>
        <ListContainer>
          <StyledRow>
            <Number>1</Number>
            <ListItem>Complete your cyber application with just 4 y/n questions about cyber security</ListItem>
          </StyledRow>
          <StyledRow>
            <Number>2</Number>
            <ListItem>
               Check your inbox for a link to your recommended policy and digital quote proposal
            </ListItem>
          </StyledRow>
          <StyledRow>
            <Number>3</Number>
            <ListItem>
               Let us know which option you want and we’ll email you an invoice
            </ListItem>
          </StyledRow>
          <StyledRow>
            <Number>4</Number>
            <ListItem>Pay online or finance your policy to spread out payments</ListItem>
          </StyledRow>
         
        </ListContainer>
        <Paragraph>
          If you need help at any point, our experts are here to help by phone,
          email, or chat. Find us in the bottom left corner!
        </Paragraph>
        <LandingActionRow>
          <CtaButton to="/getstarted">Get Started</CtaButton>
          <LoginRow>
            <LoginText>Already have an account?</LoginText>
            <LoginButton onClick={handleLogin}>Log In</LoginButton>
          </LoginRow>
        </LandingActionRow>
      </Wrapper>
    </>
  );
};

const LoginRow = styled(Row)`
  justify-content: center;
`;

const LandingActionRow = styled.div`
  text-align: center;
`;

const LoginButton = styled(NavLink)`
  color: ${(props) => props.theme.darkblue};
  border: none;
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
  text-decoration: none;
  font-family: "Nunito Sans", sans-serif;
  margin-top: 20px;

  :hover {
    cursor pointer;
    color: ${(props) => props.theme.blue};
  }
`;

const LoginText = styled.p`
  margin-top: 20px;
  font-size: 14px;
`;

const Wrapper = styled.div`
  max-width: 875px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 10px;
`;

const SubText = styled(Paragraph)`
  margin-top: 0;
`;

const ListContainer = styled.div``;

const Number = styled.div`
  background: ${(props) => props.theme.blue};
  color: ${(props) => props.theme.white};
  border-radius: 30px;
  height: 30px;
  width: 30px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 15px 0 15px 11px;
`;

const ListItem = styled.p`
  color: ${(props) => props.theme.offblack};
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  margin: 10px;
`;

const StyledRow = styled(Row)`
  align-items: center;
`;

export default Landing;
