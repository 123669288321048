import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import SmoothCollapse from "react-smooth-collapse";
import { Header, ActionRow, Next, ErrorText } from "../styles/global";
import { useGetApplications } from "../hooks/useGetApplications";
import { useGetUser } from "../hooks/useGetUser";
import { useStore } from "../utils/store";
import CoverageType from "../components/CoverageType";
import RepsIcon from "../images/reps.png";
import CyberIcon from "../images/cyber.png";
import BondsIcon from "../images/bonds.png";
import OfficersIcon from "../images/officers.png";
import ErrorsIcon from "../images/errors.png";
import Arrow from "../images/arrow.svg";
import Edit from "../images/edit.svg";
import LiabilitiesIcon from "../images/liabilities.png";
import { lobLogic } from "../utils/lobLogic";
import { listOfCoverage } from "..//utils/coverageLogic";
import useAuth from "../auth/useAuth";
import { StyledInput } from "../components/Input";
import { coverage } from "../utils/enums";
import { useUpdateLob } from "../hooks/useUpdateLob";
import UpArrow from "../images/uparrow.svg";
import DownArrow from "../images/downarrow.svg";

const validationSchema = z.object({
  lineOfBusiness: z
    .string()
    .array()
    .min(1, { message: "Coverage type is required" })
    .or(z.string().nonempty({ message: "Coverage type is required" })),
});

const Binder = () => {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      lineOfBusiness: [],
    },
  });

  const [tempLob, setTempLob] = useState([]);
  const [otherCoverages, setOtherCoverages] = useState([]);
  const [coverages, setCoverages] = useState([]);
  const [enableSearch, setEnableSearch] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const searchEmail = useStore((state) => state.searchEmail);
  const updateSearchEmail = useStore((state) => state.updateSearchEmail);
  const updateStep = useStore((state) => state.updateStep);
  const start = useStore((state) => state.start);
  const updateStart = useStore((state) => state.updateStart);
  const updateQuoted = useStore((state) => state.updateQuoted);
  const updateForm = useStore((state) => state.updateForm);
  const form = useStore((state) => state.form);

  const navigate = useNavigate();
  const { email, getAdmin } = useAuth();
  const admin = getAdmin();
  const mutation = useUpdateLob(email);

  const updateTempLob = (payload, checked) => {
    if (checked) {
      return setTempLob([...tempLob, payload]);
    } else {
      return setTempLob(tempLob.filter((item) => item !== payload) || []);
    }
  };

  const handleChange = (e) => {
    setEnableSearch(false);
    updateSearchEmail(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    if (searchEmail !== "") {
      setEnableSearch(true);
    }
  };

  const handleClick = () => {
    if (Object.keys(errors).length !== 0) return;
    updateStep(1);
    const payload = {
      lineOfBusiness: tempLob,
    };
    updateStart({
      lineOfBusiness: tempLob,
    });
    mutation.mutate(payload, {
      onSuccess: () => {
        navigate(lobLogic(tempLob));
        setTempLob([]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const { data: applicationsData, isInitialLoading } = useGetApplications(
    email,
    !admin
  );
  const { data: adminApplications } = useGetApplications(
    searchEmail,
    enableSearch
  );
  const { data: userData, isInitialLoading: userDataLoading } =
    useGetUser(email);

  const externalApps = start.lineOfBusiness.filter(
    (lob) => lob !== coverage.CYBER
  );

  useEffect(() => {
    if (userData === undefined && !userDataLoading && !admin) {
      navigate("/getstarted");
    } 
  }, [userData, userDataLoading]);

  useEffect(() => {
    if (userData) {
      updateStart({
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        industry: userData.industry,
        lineOfBusiness: userData.lineOfBusiness,
        hasExternalFunding: userData.hasExternalFunding,
        hasCustomers: userData.hasCustomers,
        hasEmployees: userData.hasEmployees,
        company: userData.company,
      });
      const accountInfo = {
        contactFirstName: userData.firstName,
        contactLastName: userData.lastName,
        contactEmail: userData.email,
        organizationName: userData.company,
      };
      updateForm({ ...form, ...accountInfo });
    }
  }, [userData, updateStart]);

  useEffect(() => {
    if (!userDataLoading && !isInitialLoading && applicationsData && applicationsData.apps) {
      console.log("app length:"+applicationsData.apps.length);
      if (applicationsData.apps.length===0) {
        navigate("/application");
     } 
      applicationsData.apps.map((app) => {
        if (app.status === "QUOTED") {
          updateQuoted(app);
        }
      });
    }
  }, [userDataLoading,applicationsData, isInitialLoading]);

  const appStatusDisplay = (status) => {
    switch (status) {
      case "QUOTED":
        return <QuotesReady>Quotes Ready</QuotesReady>;
      case "SUBMITTED_ERROR":
      case "NEED_MORE_INFO":
      case "IN_REVIEW":
        return <AppStatus>In Review</AppStatus>;
      case "SUBMITTED":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "READY_TO_SUBMIT":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "READY_TO_RESUBMIT":
        return <AppStatus>Retrieving quotes</AppStatus>;
      case "ERROR":
        return <AppStatus>In Review</AppStatus>;
      case "SAVED":
      case "INCOMPLETE":
        return <AppStatus>Incomplete</AppStatus>;
      case "REQUESTED":
        return <AppStatus>Requested</AppStatus>;
      default:
        return;
    }
  };

  const appTypeDisplay = (type) => {
    switch (type) {
      case coverage.CYBER:
        return <AppType>Cyber</AppType>;
      case coverage.ERRORSANDOMISSIONS:
        return <AppType>Errors & Omissions</AppType>;
      case coverage.DIRECTORSANDOFFICERS:
        return <AppType>Directors & Officers</AppType>;
      case coverage.EMPLOYMENTPRACTICESLIABILITY:
        return <AppType>Employment Practices Liability</AppType>;
      case coverage.REPSANDWARRANTY:
        return <AppType>Reps & Warranty</AppType>;
      case coverage.BONDS:
        return <AppType>Bonds</AppType>;
      default:
        return;
    }
  };

  const coverageTypes = () => {
    if (admin) {
      return "Other";
    } else if (start.industry) {
      return start.industry;
    } else {
      return "Other";
    }
  };

  useEffect(() => {
    const filteredCoverages = () => {
const fundingIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.DIRECTORSANDOFFICERS
      );
      const employeeIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.EMPLOYMENTPRACTICESLIABILITY
      );
      const customerIndex = listOfCoverage(coverageTypes()).indexOf(
        coverage.ERRORSANDOMISSIONS
      );
     
      const filteredArray = listOfCoverage(coverageTypes());
     if (fundingIndex !== -1 && start.hasExternalFunding === false) {
        filteredArray.splice(fundingIndex, 1);
      }
      if (employeeIndex !== -1 && start.hasEmployees === false) {
        filteredArray.splice(employeeIndex, 1);
      }
      if (customerIndex !== -1 && start.hasCustomers === false) {
        filteredArray.splice(customerIndex, 1);
      }
      return filteredArray;
    };

    const finalCoverages = filteredCoverages().filter(
      (lob) => !start.lineOfBusiness.includes(lob)
    );

    if (applicationsData && applicationsData.apps.length > 0) {
      applicationsData.apps.map((app) => {
        const lobIndex = finalCoverages.indexOf(app.type);
        if (lobIndex > -1) {
          finalCoverages.splice(lobIndex, 1);
        }
      });
      setCoverages(finalCoverages);

      const newOtherCoverages = listOfCoverage("Other").filter(
        (lob) => !finalCoverages.includes(lob)
      );
      applicationsData.apps.map((app) => {
        const lobIndex = newOtherCoverages.indexOf(app.type);
        if (lobIndex > -1) {
          newOtherCoverages.splice(lobIndex, 1);
        }
      });
      setOtherCoverages(
        newOtherCoverages.filter((lob) => !start.lineOfBusiness.includes(lob))
      );
    } else {
      setCoverages(
        filteredCoverages().filter((lob) => !start.lineOfBusiness.includes(lob))
      );
      const newOtherCoverages = listOfCoverage("Other")
        .filter((lob) => !filteredCoverages().includes(lob))
        .filter((lob) => !start.lineOfBusiness.includes(lob));
      setOtherCoverages(newOtherCoverages);
    }
  }, [applicationsData, start]);

  const displayIcon = (app) => {
    switch (app.status) {
      case "SUBMITTED":
      case "READY_TO_SUBMIT":
      case "READY_TO_RESUBMIT":
        return "";
      case "INCOMPLETE":
      case "SAVED":
        return (
          <Icon
            src={Edit}
            alt="edit icon"
            onClick={() => navigate(`/application/${app.id}`)}
          />
        );
      case "QUOTED":
        return (
          <>
            <Icon
              src={Edit}
              alt="edit icon"
              onClick={() => navigate(`/application/${app.id}`)}
            />
            <Icon
              src={Arrow}
              alt="arrow icon"
              onClick={() => navigate("/quotes")}
            />
          </>
        );
      default:
        return "";
    }
  };

  const displayApplications = (appData) => {
    return (
      <AppContainer>
        {appData &&
          !isInitialLoading &&
          (appData.apps.length > 0 || externalApps.length > 0) && (
            <>
              <AppHeader>My Applications</AppHeader>
              <HeaderRow>
                <Heading>Coverage Type</Heading>
                <Heading>Status</Heading>
                <Heading></Heading>
              </HeaderRow>
              {appData.apps.length > 0 &&
                appData.apps.map((app) => {
                  return (
                    <AppRow key={app.id}>
                      <AppItem>{appTypeDisplay(app.type)}</AppItem>
                      <AppItem>{appStatusDisplay(app.status)}</AppItem>
                      <ActionIcon>{displayIcon(app)}</ActionIcon>
                    </AppRow>
                  );
                })}
              {externalApps.length > 0 &&
                externalApps.map((app, i) => {
                  return (
                    <AppRow key={i}>
                      <AppItem>{appTypeDisplay(app)}</AppItem>
                      <AppItem>{appStatusDisplay("REQUESTED")}</AppItem>
                      <ActionIcon>{displayIcon(app)}</ActionIcon>
                    </AppRow>
                  );
                })}
            </>
          )}
      </AppContainer>
    );
  };

  return (
    <>
      {admin && (
        <>
          <AppHeader>Search Applications</AppHeader>
          <StyledInput
            name="searchApps"
            placeholder="e.g. johnsmith@gmail.com"
            value={searchEmail}
            onChange={handleChange}
            type="text"
            onKeyDown={handleKeyDown}
          />
          <SearchButton onClick={handleSearch}>Search</SearchButton>
        </>
      )}
      {admin
        ? displayApplications(adminApplications)
        : displayApplications(applicationsData)}
      {(userData || admin) && (
        <>
          {coverages.indexOf(coverage.CYBER)!=-1 && (
            <>
              <StyledHeader>Start your Cyber App</StyledHeader>
        
          <CoverageContainer>
            {coverages.map((c) => {
              if (c === coverage.CYBER) {
                return (
                  <CoverageType
                    key="cyber"
                    name="lineOfBusiness"
                    lob={c}
                    control={control}
                    label="Cyber"
                    iconSrc={CyberIcon}
                    register={register}
                    updateTempLob={updateTempLob}
                    tooltip="Cyber Insurance protects an organization from exposures related to its use of technology and data. Cyber exposures typically arise from data breaches, extortion, and deceptive funds theft incidents caused by criminal activity or human error. All organizations utilize technology in the delivery of their products or services and depend on technology for a wide range of functions such as communication, payments, and data storage."
                  />
                );
              }
            })}
          </CoverageContainer>
          {errors?.lineOfBusiness?.message && (
            <ErrorText>{errors.lineOfBusiness.message}</ErrorText>
          
          )}
          <ActionRow>
            <Next onClick={handleSubmit(handleClick)}>Start Application</Next>
          </ActionRow>
        </>
        )}
        </>
      )}
    </>
  );
};

const AppContainer = styled.div`
  margin-bottom: 60px;
`;

const OtherText = styled.h4`
  font-size: 16px;
  color: ${(props) => props.theme.lighterblack};
  font-weight: 500;
  margin-top: 16px;
`;

const ArrowIcon = styled.img`
  margin-left: 10px;
  margin-top: -5px;
  width: 15px;
`;

const ExpandSection = styled.div`
  display: flex;
  flex-direction: row;
  color: ${(props) => props.theme.lightblack};
`;

const SearchButton = styled(Next)`
  padding: 10px 24px;
  font-size: 15px;
`;

const ActionIcon = styled.div`
  justify-content: end;
  display: flex;
  height: 43px;
`;

const AppItem = styled.div`
  display: flex;
`;

const Heading = styled.h4`
  font-weight: 400;
  padding-left: 5px;
  margin-bottom: 10px;
`;

const HeaderRow = styled.div`
  padding: 0 24px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
`;

const AppRow = styled.div`
  background-color: ${(props) => props.theme.white};
  box-shadow: 1px 3px 14px -6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  padding: 0 24px;
  max-width: 600px;
  display: grid;
  grid-template-columns: 3fr 3fr 1fr;
  margin-bottom: 12px;
`;

const StyledHeader = styled(Header)`
  margin-bottom: 0;
  font-weight: 700;
`;

const AppHeader = styled(Header)`
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
`;

const Icon = styled.img`
  margin-top: 24px;
  margin-left: 20px;

  &:hover {
    cursor: pointer;
  }
`;

const QuotesReady = styled.p`
  background-color: ${(props) => props.theme.ready};
  padding: 8px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const AppStatus = styled.p`
  background-color: ${(props) => props.theme.pending};
  padding: 8px;
  border-radius: 8px;
  text-transform: capitalize;
`;

const AppType = styled.p`
  padding: 8px;
`;

const RecommendText = styled.h4`
  font-size: 18px;
  color: ${(props) => props.theme.offblack};
  font-weight: 500;
  margin-top: 10px;
`;

// const NoApps = styled.h4`
//   font-size: 18px;
//   color: ${(props) => props.theme.offblack};
//   font-weight: 500;
// `;

const CoverageContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  max-width: 550px;
  margin-bottom: ${(props) => (props.haserror ? "10px" : "30px")};
  position: relative;
`;

export default Binder;