import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useStartApplication } from "../../hooks/useStartApplication";
import { ActionRow, Next, Header } from "../../styles/global";
import Input from "../../components/Input";
import Dropdown from "../../components/Dropdown";
import Toggle from "../../components/Toggle";
import { industryOptions } from "../../data/selectOptions";
import { useStore } from "../../utils/store";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetUser } from "../../hooks/useGetUser";
import useAuth from "../../auth/useAuth";
import { useNavigate } from "react-router-dom";

const validationSchema = z.object({
  firstName: z.string().nonempty({ message: "First name is required" }),
  lastName: z.string().nonempty({ message: "Last name is required" }),
  email: z.string().trim().email({ message: "Must be a valid email" }),
  company: z.string().nonempty({ message: "Organization name is required" }),
});

const GetStarted = () => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      industry: "",
    },
  });

  const { email } = useAuth();
  const { data: userData } = useGetUser(email);
  const navigate = useNavigate();

  const updateStep = useStore((state) => state.updateStep);
  const step = useStore((state) => state.step);

  const mutation = useStartApplication();
  const { loginWithRedirect } = useAuth0();

  const initializeApp = async () => {
    if (Object.keys(errors).length !== 0) return;
    const totalData = getValues();
    totalData.email=totalData.email.trim();
    const payload = JSON.stringify(totalData);
    mutation.mutate(payload, {
      onSuccess: () => {
        if (userData) {
          navigate("/");
        } else {
          loginWithRedirect({
            authorizationParams: {
              login_hint: totalData.email,
              screen_hint: "signup",
            },
            appState: {
              returnTo: window.location.origin,
            },
          });
        }

        updateStep(1);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleNext = () => {
    const totalData = getValues();
    if (totalData.isTechcompany === true) {
      window.location.href =
        "https://especialty.useindio.com/sign-up/QNn603UbPXNJvk6oeN3_/";
    } else {
      initializeApp();
    }
  };

  return (
    <form>
      {step === 1 && (
        <>
          <Header>Basic Information</Header>
          <Input
            label="First Name"
            name="firstName"
            placeholder="e.g. John"
            register={register}
            hasError={errors?.firstName?.message}
            control={control}
            tooltip="This should be your legal first name."
          />
          <Input
            label="Last Name"
            name="lastName"
            placeholder="e.g. Smith"
            register={register}
            hasError={errors?.lastName?.message}
            control={control}
            tooltip="This should be your legal last name."
          />
          <Input
            label="Email"
            name="email"
            placeholder="e.g. johnsmith@gmail.com"
            register={register}
            hasError={errors?.email?.message}
            control={control}
            tooltip="This is where we will send your quotes."
            // disabled={requote}
            small={true}
          />
          <Input
            label="Organization Name"
            name="company"
            placeholder="e.g. Smith's Paper Company"
            register={register}
            hasError={errors?.company?.message}
            control={control}
            tooltip="This should be your business' full legal name.  If your organization consists of multiple entities, please use parent company name here and complete the application questions for the sum total of all entities. "
          />
          <Toggle
            label="Are you a technology company or does your organization provide technology?"
            name="isTechcompany"
            register={register}
            control={control}
            hasError={errors?.hasCustomers?.message}
            start={true}
            tooltip="Technology companies, & organizations providing technology, typically need Technology Errors & Omissions (E&O) combined with Cyber Insurance."
          />
          <ActionRow padding={true}>
            <Next onClick={handleSubmit(handleNext)}>Next: Create Account</Next>
          </ActionRow>
        </>
      )}
    </form>
  );
};

export default GetStarted;
