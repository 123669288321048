import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useController } from "react-hook-form";
import Select from "react-select";
import { theme } from "../styles/theme";
import { ErrorText, Line, StyledTooltip, selectStyles } from "../styles/global";
import { useStore } from "../utils/store";

const Dropdown = ({
  name,
  label,
  options,
  control,
  placeholder,
  start,
  tooltip,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const [showTooltip, setShowTooltip] = useState(false);
  const errorCopy = `${label} is required`;
  const updateStart = useStore((state) => state.updateStart);
  const form = useStore((state) => state.form);

  useEffect(() => {
    if (form[name] !== "") {
      handleChange({ value: form[name], label: form[name] });
    }
  }, []);

  const handleChange = (val) => {
    if (start) {
      updateStart({ industry: val.value });
    }
    field.onChange(val.value);
    setShowTooltip(false);
  };

  const handleFocus = () => {
    setShowTooltip(true);
  };

  const handleBlur = () => {
    setShowTooltip(false);
  };

  return (
    <Container>
      <Wrapper id={name} isInvalid={!!error} hasError={error}>
        <Label>{label}</Label>
        <StyledSelect
          key={field.value}
          placeholder={placeholder}
          hasError={error}
          options={options}
          styles={selectStyles}
          onChange={handleChange}
          name={name}
          value={options.find((c) => c.value === field.value)}
          onBlur={handleBlur}
          onFocus={handleFocus}
          defaultValue={form[name]}
        />
        {error && <StyledErrorText>{errorCopy}</StyledErrorText>}
      </Wrapper>
      {showTooltip && tooltip && (
        <>
          <Line haserror={error} />
          <StyledTooltip haserror={error}>{tooltip}</StyledTooltip>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledErrorText = styled(ErrorText)`
  padding: 0 0 0 15px;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => (props.hasError ? "10px" : "30px")};
`;

const Label = styled.label`
  padding: 0 16px 8px 16px;
  color: ${theme.offblack};
  font-size: 16px;
  weight: 500;
  overflow-wrap: break-word;
  max-width: 350px;
  text-align: left;
`;

const StyledSelect = styled(Select)`
  width: 430px;
  border-radius: 8px;
  border: ${(props) =>
    props.hasError
      ? `1px solid ${props.theme.red}`
      : `1px solid ${props.theme.white}`};

  &:focus {
    border: ${(props) =>
      props.hasError
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};
  }

  &:hover {
    border: ${(props) =>
      props.hasError
        ? `1px solid ${props.theme.red}`
        : `1px solid ${props.theme.white}`};
  }
`;

export default Dropdown;
